import React, { useState, useEffect} from 'react' ;
import Header from "../common/Header" ;
import LeftNav from "../common/LeftNav" ;
import axios from "axios" ;
import { Link } from "react-router-dom" ;

const ViewStudent = () => {
  const [cat, setCat] = useState([]);

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    const result = await axios.get(
      "https://exam.system.webzed.in/php-files/admin/register-login/view-students.php"
    );
    setCat(result.data.records);
    //console.log(result.data)
  };

  const deleteUser = (id) => {
    axios
      .delete(
        "https://exam.system.webzed.in/php-files/admin/register-login/delete.php",
        { data: { id: id } }
      )
      .then((result) => {
        loadUsers();
      })
      .catch(() => {
        alert("Cannot Delete the code");
      });
  };

  return (
    <>
    
    <Header/>
    <LeftNav/>
    <div className='container col-lg-10'>
      <div className='box'>
        <div className='heading'><h2 className='text-center'>View Student Details</h2></div><hr/>
        <br/>
        <table class="table table-bordered table-striped">
    <thead>
      <tr>
        <th>Sl No.</th>
        <th>Course Name</th>
        <th>First Name</th>
        <th>Last Name</th>
        <th>UserName</th>
        <th>password</th>
        <th>Edit</th>
        <th>Delete</th>
      </tr>
    </thead>

<tbody>
  {cat.map((cat, index) => (
    <tr>
      <td>{index + 1}</td>
      <td>{cat.course_name}</td>
      <td>{cat.first_name}</td>
      <td>{cat.last_name}</td>
      <td>{cat.username}</td>
      <td>{cat.password}</td>
      <td><Link to={`/edit-student/${cat.id}`}> {<i class="fa fa-edit"></i>}</Link></td>
      <td><Link to="" onClick={() => deleteUser(cat.id)}>&nbsp;{<i class="fa fa-trash" aria-hidden="true"></i>}</Link></td>
    </tr>
  ))}
</tbody>
  </table>
      </div>
    </div>
    </>
  )
}

export default ViewStudent