import React, {useState, useEffect} from 'react';
import Header from "../common/Header" ;
import LeftNav from "../common/LeftNav" ;
import { useParams } from 'react-router-dom';
import axios from 'axios';

const Result = () => {

  const {username} = useParams();
  const [result, setResult] = useState([]);

  useEffect(() => {
    loadResults();
  }, []);

  const loadResults = async () => {
    const result = await axios.get("https://exam.system.webzed.in/php-files/student/single_result.php?username="+username);
    setResult(result.data.records);
    // console.log(result.data.records)
  };

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     myFunction();
  //   }, 1000); 
  //   return () => clearTimeout(timeout);
  // }, []);
  // const myFunction = () => {
  //   alert('Function called at timeout!');
  // };
  return (
    <>
    <Header/>
    <LeftNav/>
    <div className='container'>
    <div className='box'>
      <div className='heading'>
        <h2 className='text-center'>Your Results</h2>
      </div><hr/>
      <div className='result-table'>
      <table class="table table-bordered table-striped">
    <thead>
      <tr>
        <th>Sl No.</th>
        <th>Course Name</th>
        <th>Topic Name</th>
        <th>Total Questions</th>
        <th>Right Answers</th>
        <th>Wrong Answers</th>
      </tr>
    </thead>

<tbody>
  {result.map((result, index) => (
    <tr>
      <td>{index + 1}</td>
      <td>{result.course_name}</td>
      <td>{result.topic_name}</td>
      <td>{result.total_ans}</td>
      <td>{result.correct_ans}</td>
      <td>{result.wrong_ans}</td>
    </tr>
  ))}
</tbody>
  </table>
      </div>
    </div>
    </div>
    </>
  )
}

export default Result