import React, {useRef, useEffect} from 'react';
import Header from "../common/Header" ;
import LeftNav from "../common/LeftNav" ;
import { Link, useParams } from 'react-router-dom';
import './Exam.css';
import axios from 'axios';

const ExamGiven = () => {

  const {username, topic_id} = useParams();
  const submitButtonRef = useRef();

  useEffect(() => {
    const timeout = setTimeout(() => {
      const submitButton = submitButtonRef.current;
      submitButton.click();
    }, 1000); 
    return () => clearTimeout(timeout);
  }, [])

  const loadResult = async () => {
    await axios
      .post(
        `https://exam.system.webzed.in/php-files/student/test/exam_result.php?username=${username}&topic_id=${topic_id}`);
  };
  
  return (
    <>
    <Header/>
    <LeftNav/>
    <div className='container'>
    <div class="congratulation-area text-center mt-5">
        <div class="container">
            <div class="congratulation-wrapper">
                <div class="congratulation-contents center-text">
                    <div class="congratulation-contents-icon">
                        <i class="fas fa-check"></i>
                    </div>
                    <h4 class="congratulation-contents-title"> Congratulations! </h4>
                    <p class="congratulation-contents-para"> Your Answers have been Submitted. </p>
                    <div class="btn-wrapper mt-4">
                        <Link class="cmn-btn btn-bg-1" to={`/result/${username}`}>See Results</Link>
                        <button onClick={loadResult} type='submit' ref={submitButtonRef} hidden></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    </>
  )
}

export default ExamGiven