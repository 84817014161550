import React, {useState, useEffect, useRef} from 'react';
import Header from "../common/Header" ;
import LeftNav from "../common/LeftNav" ;
import "../user.css";
import { useTimer } from  "reactjs-countdown-hook";
import { useNavigate,useParams, useLocation } from 'react-router-dom';
import axios from "axios";

const StartExam = () => {
  const submitButtonRef = useRef();

  const navigate = useNavigate();
  const location = useLocation();
  const time = (location.state.time)*60;
  const {username,topic_id} = useParams();

  const {
    seconds,
    minutes,
    resume,
    } = useTimer(time, handleTimerFinish);
    
    function  handleTimerFinish() {
      alert("times up!");
      const submitButton = submitButtonRef.current;
      submitButton.click();
    }

    useEffect(() => {
      resume();
      loadUsers();
    }, [])

  const [data, setData] = useState([]);

  const loadUsers = async () => {
    const result = await axios.get(
      "https://exam.system.webzed.in/php-files/student/test/view-questions-by-id.php?topic_id="+topic_id);
    setData(result.data.records);
    // console.log("recordssss"+result.data.records);
  };

  const [answers, setAnswers] = useState([]);
  const handleChange = (e) => {
    setAnswers({ ...answers, [e.target.name]: e.target.value });
    //console.log(data)
  };

  const submitForm = async (e) => {
    e.preventDefault();
    await axios
      .post(`http://localhost/my_files/exam-system/student/test/submit_answers.php?username=${username}&topic_id=${topic_id}`,answers)
      .then((result) => {
        console.log(result);
        if (result.data.status === "valid") {
          navigate(`/exam-given/${username}/${topic_id}`);
          // alert("Successfully Added data");
        }
         else {
          alert("There is problem in adding,please try again");
        }
      });
  };


  return (
    <>
    <Header/>
    <LeftNav/>
    <div className='container'>
    <div className='test-time'>
      <div id="min">{`${minutes}`}</div>
      <span class="divider">:</span>
      <div id="sec">{`${seconds}`}</div>
    </div>
    <div className='box'>
      <div className='heading'>
        <h2 className='text-center'>Exam Started</h2>
      </div><hr/>
      <div className='box'>
        <div className='form-box'>
      <form onSubmit={(e) => submitForm(e)}>
        {data.map((data, index) => (
      <div>
        <div className='row'>
          <div className='col-lg-3'>Question : {++index}</div>
          <div className='col-lg-7'>
            <div dangerouslySetInnerHTML={{ __html: data.question }} name="question" className="form-control form-control-lg"/>
          </div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-3'>Option A :</div>
          <div className='col-lg-7 form-check form-check-inline'>
          <input type="radio" class="form-check-input" name={data.id} value="opt_a" onChange={(e) => handleChange(e)}/>
          <span className='form-control'>{data.opt_a}</span>
          </div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-3'>Option B :</div>
          <div className='col-lg-7 form-check form-check-inline'>
          <input type="radio" class="form-check-input" name={data.id} value="opt_b" onChange={(e) => handleChange(e)}/>
          <span className='form-control'>{data.opt_b}</span>
          </div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-3'>Option C :</div>
          <div className='col-lg-7 form-check form-check-inline'>
          <input type="radio" class="form-check-input" name={data.id} value="opt_c" onChange={(e) => handleChange(e)}/>
          <span className='form-control'>{data.opt_c}</span>
          </div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-3'>Option D :</div>
          <div className='col-lg-7 form-check form-check-inline'>
          <input type="radio" class="form-check-input" name={data.id} value="opt_d" onChange={(e) => handleChange(e)}/>
          <span className='form-control'>{data.opt_d}</span>
          </div>
        </div>
        <br/>
        <hr/>
      </div>
      ))}
      <br/>
        <div className='row'>
          <div className='col-lg-12 text-center'>
            <button type="submit" className='btn btn-md btn-primary' value="submit" ref={submitButtonRef}>
              Submit Answers
            </button></div>
        </div>
      </form>

      </div>
      </div>
    {/* </div> */}

        <div>
        </div>
    </div>
  </div>
    </>
  )
}

export default StartExam