import React, { useState, useEffect} from 'react' ;
import Header from "../common/Header" ;
import LeftNav from "../common/LeftNav" ;
import axios from "axios" ;
import { Link } from "react-router-dom" ;

const ViewTopics = () => {

  const [topic, setTopic] = useState([]);

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    const result = await axios.get(
      "https://exam.system.webzed.in/php-files/admin/topics/view_topic.php"
    );
    setTopic(result.data.records);
    //console.log(result.data)
  };

  const deleteUser = (id) => {
    axios
      .delete(
        "https://exam.system.webzed.in/php-files/admin/topics/delete_topic.php",
        { data: { id: id } }
      )
      .then((result) => {
        loadUsers();
      })
      .catch(() => {
        alert("Cannot Delete the code");
      });
  };

  return (
    <>
    
    <Header/>
    <LeftNav/>
    <div className='container col-lg-10'>
      <div className='box'>
        <div className='heading'><h2 className='text-center'>View Topics</h2></div><hr/>
        <br/>
        <table class="table table-bordered table-striped">
    <thead>
      <tr>
        <th>Sl No.</th>
        <th>Course Id</th>
        <th>Course Name</th>
        <th>Topic Name</th>
        <th>Time</th>
        <th>Description</th>
        <th>Edit</th>
        <th>Delete</th>
      </tr>
    </thead>

    <tbody>
      {topic.map((topic, index) => (
      <tr>
        <td>{index + 1}</td>
        <td>{topic.course_id}</td>
        <td >{topic.course_name}</td>
        <td>{topic.topic_name}</td>
        <td>{topic.time}</td>
        <td>{topic.description}</td>
        <td><Link to={`/edit-topic/${topic.id}`}>{<i class="fa fa-edit"></i>}</Link></td>
        <td className="col-md-2"><Link to="" onClick={() => deleteUser(topic.id)}> &nbsp;{<i class="fa fa-trash" aria-hidden="true"></i>}</Link></td>
      </tr>
      ))}
    </tbody>
  </table>
      </div>
    </div>
    </>
  )
}

export default ViewTopics