import React, { useState, useEffect } from 'react' ;
import Header from "../common/Header" ;
import LeftNav from "../common/LeftNav" ;
import { useNavigate, useParams  } from "react-router-dom";
import axios from "axios";

const EditCourse = () => {

  let history = useNavigate();
  const { id } = useParams();

  const [data, setData] = useState({
    course_name: "",
    description: "",
  });

  useEffect(() => {
    loadUsers();
  }, []);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    //console.log(data)
  };

  const { course_name, description } = data;

  const updateForm = async (e) => {
    e.preventDefault();

    // console.log(data);

    await axios
      .post(
        "https://exam.system.webzed.in/php-files/admin/courses/update_course.php",
        data
      )
      .then((result) => {
        console.log(result);
        if (result.data.status === "valid") {
          history(`/view-course`);
        } else {
          alert("There is problem in adding,please try again");
        }
      });
  };

  const loadUsers = async () => {
    const result = await axios.get(
      "https://exam.system.webzed.in/php-files/admin/courses/edit_course.php?id=" +
        id
    );
    setData(result.data);
    console.log(result.data);
  };

  return (

    <>
    <Header/>
    <LeftNav/>
    <div className='container col-lg-7'>
      <div className='box'>
        <div className='heading'><h2 className='text-center'>Edit Courses</h2></div><hr/>
        <br/>
        <div className='form-box'>
      <form onSubmit={(e) => updateForm(e)}>
        <br/>
        <div className='row'>
          <div className='col-lg-3'>Course Name:</div>
          <div className='col-lg-7'><input type="text" name="course_name" className="form-control" value={course_name} onChange={(e) => handleChange(e)} class="form-control" placeholder="Add New Course" /></div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-3'>Description:</div>
          <div className='col-lg-7'><textarea name="description" value={description} onChange={(e) => handleChange(e)} class="form-control" placeholder="Course Description" /></div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-12 text-center'><button type='submit' className='btn btn-md btn-primary'>Update Course</button></div>
        </div>
      </form>
      </div>
      </div>
    </div>
    </>
  )
}

export default EditCourse