import React, { useState, useEffect } from "react";
import Header from "../common/Header";
import LeftNav from "../common/LeftNav";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

const QuestionDetails = () => {
  const { id } = useParams();
  const [cat, setCat] = useState([]);

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    const result = await axios.get(
      "https://exam.system.webzed.in/php-files/admin/questions/view_question_by_id.php?id=" +
        id
    );
    setCat(result.data.records);
    // console.log(result.data.records);
  };

  const deleteUser = (id) => {
    console.log("The question id is = " + id);
    axios
      .delete(
        "https://exam.system.webzed.in/php-files/admin/questions/delete_question.php",
        { data: { id: id } }
      )
      .then((result) => {
        loadUsers();
      })
      .catch(() => {
        alert("Cannot Delete the code");
      });
  };

  return (
    <>
      <Header />
      <LeftNav />
      <div className="container col-lg-10">
        <div className="box">
          <div className="heading">
            <h2 className="text-center">View Questions</h2>
          </div>
          <hr />
          <br />
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th className="text-left">Sl No.</th>
                <th>Topic Name</th>
                <th>Question</th>
                <th>Opt A</th>
                <th>Opt B</th>
                <th>Opt C</th>
                <th>Opt D</th>
                <th>Correct Answer</th>
                <th className="text-center">Edit</th>
                <th className="text-center">Delete</th>
              </tr>
            </thead>

            <tbody>
              {cat.map((cat, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>
                    {cat.topic_name}
                  </td>
                  <td><div dangerouslySetInnerHTML={{ __html: cat.question }} /></td>
                  <td>{cat.opt_a}</td>
                  <td>{cat.opt_b}</td>
                  <td>{cat.opt_c}</td>
                  <td>{cat.opt_d}</td>
                  <td>{cat.correct_ans}</td>
                  <td>
                    <Link to={`/edit-question/${cat.id}`}>
                      {<i class="fa fa-edit"></i>}
                    </Link>
                  </td>
                  <td className="col-md-2">
                    <Link to="" onClick={() => deleteUser(cat.id)}>
                      &nbsp;{<i class="fa fa-trash" aria-hidden="true"></i>}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default QuestionDetails;
