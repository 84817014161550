import React, {useState} from 'react';
import { NavLink, Link } from 'react-router-dom'
import "./style.css";
import {AiFillLock, AiFillHome} from "react-icons/ai";
import {FaUserAlt} from "react-icons/fa";
import {GiHamburgerMenu} from "react-icons/gi";
import { useNavigate } from 'react-router-dom';

const Header = () => {
  
  const [isActive, setIsActive] = useState(false);

  const handleClick = event => {
    setIsActive(current => !current);
  };

  let navigate = useNavigate(); 

  var user = sessionStorage.getItem("admin_username");

  if(user == null){
    var user="Please Log in"
  }
  const AdminLogOut = ()=>{
    sessionStorage.removeItem('admin_email');
    sessionStorage.removeItem('admin_username');
    sessionStorage.clear();
    navigate(`/adminLogin`);
  }
  return (
    <>
    <div id="myTopnav" className={`topnav ${isActive ? 'responsive' : ''}`}>
  <Link to="" className='first_child'><span className='head-icon'><FaUserAlt/></span>Welcome : {user}</Link>
  <Link to="/adminHome"><span className='head-icon'><AiFillHome/></span>Home</Link>
  <a onClick={AdminLogOut} className='logout' to=""><span className='head-icon'><AiFillLock/></span>Logout</a>
  <a class="icon" onClick={handleClick}>
    <GiHamburgerMenu/>
  </a>
</div>
    </>
  )
}

export default Header