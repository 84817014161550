import React, { useState, useEffect } from 'react';
import Header from "../common/Header" ;
import LeftNav from "../common/LeftNav" ;
import { Link } from "react-router-dom";
import axios from "axios";
import { BsQuestionOctagonFill } from "react-icons/bs";
import "./question.css";

const ViewQuestionTopics = () => {
  const [cat, setCat] = useState([]);

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    const result = await axios.get(
      "https://exam.system.webzed.in/php-files/admin/topics/view_topic.php"
    );
    setCat(result.data.records);
    // console.log(result.data.records);
  };
  return (
    <>
    <Header/>
    <LeftNav/>
    <div className='container col-lg-7'>
      <div className='box'>
        <div className='heading'><h2 className='text-center'>Topics</h2></div><hr/>
        <p className='text-center'>Click The below topic to see Questions</p>

        <div className='row'>
          {cat.map((cat) => (
        <div className='col-sm-3'>
        <div className="icon">
        <Link to={`/quest-detail/${cat.id}`}><BsQuestionOctagonFill /></Link>
        <p className="text-center icon-text">{cat.topic_name}</p>
        </div>
        </div>
        ))}
        </div>
        
      </div>
    </div>
    </>
  )
}

export default ViewQuestionTopics