import React, { useState, useEffect } from 'react' ;
import Header from "../common/Header" ;
import LeftNav from "../common/LeftNav" ;
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AddTopics = () => {

  const [courseid, setCourseid] = useState([]);
  useEffect(() => {
    loadUsers();
  }, []);
  const loadUsers = async () => {
    const result = await axios.get(
      "https://exam.system.webzed.in/php-files/admin/courses/course_id.php"
    );
    setCourseid(result.data.records);
    // console.log(result.data.records);
  };

  let history = useNavigate();
  const [cat, setCat] = useState({
    course_id: "",
    topic_name: "",
    time:"",
    description: "",
  });
  const { topic_name,time, description } = cat;

  const handleChange = (e) => {
    setCat({ ...cat, [e.target.name]: e.target.value });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    await axios
      .post(
        "http://localhost/my_files/exam-system/admin/topics/add_topic.php",
        cat
      )
      .then((result) => {
        console.log(result.data);
        if (result.data.status === "valid") {
          history(`/add-question`);
        } else {
          alert("There is problem in adding,please try again");
        }
      });
  };

  return (
    <>
    <Header/>
    <LeftNav/>
    <div className='container col-lg-7'>
      <div className='box'>
        <div className='heading'><h2 className='text-center'>Add Topics</h2></div><hr/>
        <br/>
        <div className='form-box'>
      <form onSubmit={(e) => submitForm(e)}>
        <div className='row'>
          <div className='col-lg-3'>Course Name:</div>
          <div className='col-lg-7'>
          <select className="form-control" name="course_id"  onChange={(e) => handleChange(e)} >
                      <option value="0">Select Course</option>
                      {courseid.map((courseid) => (
                        <option
                          value={courseid.id + "_" + courseid.course_name}
                        >
                          {courseid.course_name}
                        </option>
                      ))}
                    </select>
          </div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-3'>Topic Name:</div>
          <div className='col-lg-7'><input type="text" name="topic_name" className="form-control" value={topic_name} onChange={(e) => handleChange(e)} class="form-control" placeholder="Add New Topic" /></div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-3'>Time (In mins):</div>
          <div className='col-lg-7'><input type="number" name="time" value={time} onChange={(e) => handleChange(e)} class="form-control" placeholder="Enter Time For Exam" /></div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-3'>Description:</div>
          <div className='col-lg-7'><textarea type="text" name="description" value={description} onChange={(e) => handleChange(e)} class="form-control" placeholder="Topic Description" /></div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-12 text-center'><button className='btn btn-md btn-primary'>Add Topic</button></div>
        </div>
      </form>
      </div>
      </div>
    </div>
    </>
  )
}

export default AddTopics