import React, { useState, useEffect } from 'react' ;
import Header from "./common/Header" ;
import LeftNav from "./common/LeftNav" ;
import { useNavigate } from 'react-router-dom';
import {FaUserGraduate} from 'react-icons/fa';
const AdminHome = () => {

  let navigate = useNavigate();

  //To check If User has Logged IN
  // const [auth, setAuth] = useState("");
  // useEffect(() => {
  //   var auth = sessionStorage.getItem("admin_email");
  //   setAuth(auth);
  // }, []);

  // if (auth === null) {
  //   navigate("/adminlogin");
  // }
  // else{
  //   navigate(`/AdminHome`);
  // }
  return (
    <>
      <Header />
      <LeftNav />
      <div className='container col-lg-8'>
      <div className='box'>
        <div className='heading'><h2 className='text-center'>Admin Panel</h2></div><hr/>
          <p className='text-center'>All Admin Are Listed Below.</p>
        <br/>
      </div>
    </div>
    </>
  )
}

export default AdminHome