import React from 'react' ;
import { Link } from 'react-router-dom' ;
import { RiAdminFill } from "react-icons/ri";
import { FaUserGraduate } from "react-icons/fa";
import "./Home.css" ;
const Home = () => {
  return (
    <>
        <div className='main-page'>
            <div className='main-box'>
                <div className='icon text-center'>
                <Link to="/adminLogin">
                <RiAdminFill />
                </Link>
                </div>
                <div className='text-center icon-text'>Admin</div>
            </div>
            <div className='main-box'>
                <div className='icon text-center'>
                <Link to="/Userlogin">
                <FaUserGraduate />
                </Link>
                </div>
                <div className='text-center icon-text'>User</div>
            </div>
        </div>
    </>
  )
}

export default Home