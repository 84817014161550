import React, {useState, useEffect} from 'react';
import Header from "../common/Header" ;
import LeftNav from "../common/LeftNav" ;
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';

const AllResults = () => {
  const [result, setResult] = useState([]);

  useEffect(() => {
    loadResults();
  }, []);

  const loadResults = async () => {
    const result = await axios.get("https://exam.system.webzed.in/php-files/student/main-result.php");
    setResult(result.data.records);
    // console.log(result.data.records)
  };
  return (
    <>
    <Header/>
    <LeftNav/>
    <div className='container'>
    <div className='box'>
      <div className='heading'>
        <h2 className='text-center'>Your Results</h2>
      </div><hr/>
      <div className='result-table'>
      <table class="table table-bordered table-striped">
    <thead>
      <tr>
        <th>Sl No.</th>
        <th>UserName</th>
        <th>Course Name</th>
        <th>Topic Name</th>
        <th>Total Questions</th>
        <th>Right Answers</th>
        <th>Wrong Answers</th>
      </tr>
    </thead>

<tbody>
  {result.map((result, index) => (
    <tr>
      <td>{index + 1}</td>
      <td>{result.username}</td>
      <td>{result.course_name}</td>
      <td>{result.topic_name}</td>
      <td>{result.total_ans}</td>
      <td>{result.correct_ans}</td>
      <td>{result.wrong_ans}</td>
    </tr>
  ))}
</tbody>
  </table>
      </div>
    </div>
    </div>
    </>
  )
}

export default AllResults;