import React, { useState, useEffect } from "react";
import Header from "../common/Header";
import LeftNav from "../common/LeftNav";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AddStudent = () => {

  const [courseid, setCourseid] = useState([]);
  useEffect(() => {
    loadUsers();
  }, []);
  const loadUsers = async () => {
    const result = await axios.get(
      "https://exam.system.webzed.in/php-files/admin/courses/course_id.php"
    );
    setCourseid(result.data.records);
    // console.log(result.data.records);
  };




  let history = useNavigate();

  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    course_id:"",
    username: "",
    password: "",
  });

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    //console.log(data)
  };

  const submitForm = (e) => {
    e.preventDefault();
    const sendData = {
      first_name: data.first_name,
      last_name: data.last_name,
      course_id: data.course_id,
      username: data.username,
      password: data.password,
    };

    // console.log(sendData);

    axios
      .post(
        "https://exam.system.webzed.in/php-files/student/register-login/register.php",
        sendData
      )
      .then((result) => {
        console.log(result.data);
        if (result.data.status === "valid") {
          history(`/view-student`);
        } else {
          alert("Invalid User");
        }
      });
  };

  return (
    <>
    <Header/>
    <LeftNav/>
    <div className='container col-lg-7'>
      <div className='box'>
        <div className='heading'><h2 className='text-center'>Add Student Details</h2></div><hr/>
        <br/>
        <div className='form-box'>
      <form onSubmit={(e) => submitForm(e)}>
        <br/>
        <div className='row'>
          <div className='col-lg-3'>Course Name:</div>
          <div className='col-lg-7'><select className="form-control" name="course_id"  onChange={handleChange} >
           <option value="0">Select Course</option>
                      {courseid.map((courseid) => (
                        <option
                          value={courseid.id + "_" + courseid.course_name}
                        >
                          {courseid.course_name}
                        </option>
                      ))}
                    </select></div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-3'>First Name :</div>
          <div className='col-lg-7'><input type="text" name="first_name" className="form-control" value={data.first_name} onChange={(e) => handleChange(e)} class="form-control" placeholder="First Name" /></div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-3'>Last Name :</div>
          <div className='col-lg-7'><input type="text" name="last_name" className="form-control" value={data.last_name} onChange={(e) => handleChange(e)} class="form-control" placeholder="Last Name" /></div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-3'>UserName :</div>
          <div className='col-lg-7'><input type="text" name="username" className="form-control" value={data.username} onChange={(e) => handleChange(e)} class="form-control" placeholder="UserName" /></div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-3'>Password :</div>
          <div className='col-lg-7'><input type="password" name="password" value={data.password} onChange={(e) => handleChange(e)} class="form-control" placeholder="Enter Password" /></div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-12 text-center'><button type='submit' className='btn btn-md btn-primary'>Add Student</button></div>
        </div>
      </form>
      </div>
      </div>
    </div>
    </>
  )
}

export default AddStudent