import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { NavLink, Link , useParams} from 'react-router-dom';
import "./style.css"
import {GiHamburgerMenu} from "react-icons/gi";

function LeftNav() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isActive, setIsActive] = useState(false);

  const handleClick = event => {
    // toggle isActive state on click
    setIsActive(current => !current);
    console.log(isActive);
  };

  const {username} = useParams();

  return (
    <>
      <Button variant="primary" onClick={handleShow} className='menu-btn'>
        <span><GiHamburgerMenu/></span>Menu
      </Button>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>By Obaidullah Zeeshan</Offcanvas.Title>
        </Offcanvas.Header>
        <div className='sidebar-main'>
        <div className='sidebar-logo'>
            <Link to="/"><div className='logo-text text-center'>Quiz <br/>Competetion</div></Link>
        </div>
        <div className='sidebar'>
        <div class="dropdown sidenav">
                <button type="button" class="btn dropdown-toggle" data-toggle="dropdown">
                Examination
                </button>
                <div class="dropdown-menu">
                <Link class="dropdown-item" to={`/CoursePage/${username}`}>Your Course</Link>
                <Link class="dropdown-item" to={`/ExamInstr/${username}`}>Exams</Link>
                </div>
          </div>
          <div class="sidenav">
                <Link to={`/result/${username}`}><button type="button" class="btn">
                Result
                </button></Link>
          </div>
        </div>
        </div>
      </Offcanvas>
    </>
  );
}

export default LeftNav;