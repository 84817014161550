import React, { useState} from 'react';
import axios from "axios";
import { NavLink,useNavigate } from "react-router-dom";

const UserLogin = () => {

    let navigate = useNavigate();

    const [user, setUser] = useState({ username: "", password: "" });
  
    const handleChange = (e) => {
      setUser({ ...user, [e.target.name]: e.target.value });
    };
  
    const submitForm = (e) => {
      e.preventDefault();
      const sendData = {
        username: user.username,
        password: user.password,
      };
  
      // console.log(sendData);
  
      axios
        .post(
          "https://exam.system.webzed.in/php-files/student/register-login/login.php",
          sendData
        )
        .then((result) => {
          if (result.data.Status === "200") {
            window.sessionStorage.setItem("user_username", result.data.username);
            window.sessionStorage.setItem("user_fullname", result.data.first_name + " " + result.data.last_name);
            // alert("The id is : "+result.data.id)
            navigate(`/UserHome/${result.data.username}`);
          } else {
            alert("Invalid User");
          }
        });
    };

  return (
    <>
    <div className="wrapper">
        <div className="logo">
            <img src="https://cdn-icons-png.flaticon.com/512/67/67902.png" alt=""/>
        </div>
        <div className="text-center mt-4 name">
            Student Login
        </div>
        <form className="p-3 mt-3" onSubmit={submitForm}>
            <div className="form-field d-flex align-items-center">
                <span className="far fa-user"></span>
                <input type="text" name="username" id="userName" placeholder="Username" onChange={handleChange}/>
            </div>
            <div className="form-field d-flex align-items-center">
                <span className="fas fa-key"></span>
                <input type="password" name="password" id="pwd" placeholder="Password" onChange={handleChange}/>
            </div>
            <button className="btn mt-3">Login</button>
        </form>
        <div className='text-center'>Go Back to <NavLink to="/" className="text-center"><span style={{fontSize: "20px"}}>Home</span></NavLink></div>
    </div>
    </>
  )
}

export default UserLogin