import React,{useState, useEffect } from 'react' ;
import Header from "../common/Header" ;
import LeftNav from "../common/LeftNav" ;
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Editor from 'react-simple-wysiwyg';


const AddQuestion = () => {

  let navigate=useNavigate();

  const [courseid, setCourseid] = useState([]);
  useEffect(() => {
    loadUsers();
  }, []);
  const loadUsers = async () => {
    const result = await axios.get(
      "https://exam.system.webzed.in/php-files/admin/topics/topic_id.php"
    );
    setCourseid(result.data.records);
    // console.log(result.data.records);
  };


  const [AddQues, setAddQues] = useState({
    topic_id:"",
    question: "",
    opt_a: "",
    opt_b: "",
    opt_c: "",
    opt_d: "",
    correct_ans: "",
  });
  const {question, opt_a, opt_b, opt_c, opt_d, correct_ans } = AddQues;

  const handleChange = (e) => {
    setAddQues({ ...AddQues, [e.target.name]: e.target.value });
    // setCat("");
  };

  const submitForm = async (e) => {
    // e.preventDefault();
    await axios.post("http://localhost/my_files/exam-system/admin/questions/add-question.php",AddQues)
      .then((result) => {
        console.log(result.data);
        if (result.data.status === "valid") {
          alert("Question Was Added");
        } else {
          alert("There is problem in adding,please try again");
        }
      });
  };
  return (
    <>
    <Header/>
    <LeftNav/>
    <div className='container col-lg-7'>
      <div className='box'>
        <div className='heading'><h2 className='text-center'>Add Questions</h2></div><hr/>
        <br/>
        <div className='form-box'>
          
      <form onSubmit={(e) => submitForm(e)}> 
      <div className='row'>
          <div className='col-lg-3'>Topic Name :</div>
          <div className='col-lg-7'>
          <select className="form-control" name="topic_id" onChange={(e) => handleChange(e)}>
            <option value="0">Select Topic</option> {courseid.map((courseid) => (
                <>
            <option value={courseid.id + "_" + courseid.topic_name}>{courseid.topic_name}
            </option> 
            </>
            ))}
          </select>
          </div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-3'>Question:</div>
          <div className='col-lg-7'>
            {/* <Editor name="question" value={question} onChange={(e) => handleChange(e)}/> */}
          <textarea name="question" className="form-control" value={question} onChange={(e) => handleChange(e)} />
          </div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-3'>Option A :</div>
          <div className='col-lg-7 form-check form-check-inline'>
          <input type="radio" class="form-check-input" name="correct_ans" value="opt_a" onChange={(e) => handleChange(e)}/>
          <input type="text" name="opt_a" className="form-control" value={opt_a} onChange={(e) => handleChange(e)} />
          </div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-3'>Option B :</div>
          <div className='col-lg-7 form-check form-check-inline'>
          <input type="radio" class="form-check-input" name="correct_ans" value="opt_b" onChange={(e) => handleChange(e)}/>
          <input type="text" name="opt_b" className="form-control" value={opt_b} onChange={(e) => handleChange(e)} />
          </div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-3'>Option C :</div>
          <div className='col-lg-7 form-check form-check-inline'>
          <input type="radio" class="form-check-input" name="correct_ans" value="opt_c" onChange={(e) => handleChange(e)}/>
          <input type="text" name="opt_c" className="form-control" value={opt_c} onChange={(e) => handleChange(e)} />
          </div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-3'>Option D :</div>
          <div className='col-lg-7 form-check form-check-inline'>
          <input type="radio" class="form-check-input" name="correct_ans" value="opt_d" onChange={(e) => handleChange(e)}/>
          <input type="text" name="opt_d" className="form-control" value={opt_d} onChange={(e) => handleChange(e)} />
          </div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-12 text-center'><button className='btn btn-md btn-primary'>Add Question</button></div>
        </div>
      </form>
      </div>
      </div>
    </div>
    </>
  )
}

export default AddQuestion