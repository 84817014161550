import React from "react";
import { useState, useEffect } from "react";
import Header from "../common/Header";
import LeftNav from "../common/LeftNav";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Editor from 'react-simple-wysiwyg';

const EditQuestion = () => {

  let history = useNavigate();
  const { id } = useParams();

  const [data, setData] = useState({
    topic_name: "",
    question: "",
    opt_a: "",
    opt_b: "",
    opt_c: "",
    opt_d: "",
    correct_ans: "",
  });

  useEffect(() => {
    loadUsers();
  }, []);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    //console.log(data)
  };

  const { topic_name, question, opt_a, opt_b, opt_c, opt_d, correct_ans } = data;

  const updateForm = async (e) => {
    e.preventDefault();

    // console.log(data);

    await axios
      .post(
        "https://exam.system.webzed.in/php-files/admin/questions/update_question.php",
        data
      )
      .then((result) => {
        console.log(result);
        if (result.data.status === "valid") {
          history(`/quest-detail/${data.topic_id}`);
        } else {
          alert("There is problem in adding,please try again");
        }
      });
  };

  const loadUsers = async () => {
    const result = await axios.get(
      "https://exam.system.webzed.in/php-files/admin/questions/edit_question.php?id=" +
        id
    );
    setData(result.data);
    console.log(result.data);
  };

  return (
    <>
    <Header/>
    <LeftNav/>
    <div className='container col-lg-7'>
      <div className='box'>
        <div className='heading'><h2 className='text-center'>Add Questions</h2></div><hr/>
        <br/>
        <div className='form-box'>
      <form onSubmit={(e) => updateForm(e)}>
        <div className='row'>
          <div className='col-lg-3'>Topic Name :</div>
          <div className='col-lg-7'>
          <input type="text" name="topic_name" className="form-control" value={topic_name} onChange={(e) => handleChange(e)} />
          </div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-3'>Question:</div>
          <div className='col-lg-7'>
            <Editor name="question" value={question} onChange={(e) => handleChange(e)}/>
          </div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-3'>Option A :</div>
          <div className='col-lg-7'>
          <input type="text" name="opt_a" className="form-control" value={opt_a} onChange={(e) => handleChange(e)} />
          </div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-3'>Option B :</div>
          <div className='col-lg-7'>
          <input type="text" name="opt_b" className="form-control" value={opt_b} onChange={(e) => handleChange(e)} />
          </div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-3'>Option C :</div>
          <div className='col-lg-7'>
          <input type="text" name="opt_c" className="form-control" value={opt_c} onChange={(e) => handleChange(e)} />
          </div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-3'>Option D :</div>
          <div className='col-lg-7'>
          <input type="text" name="opt_d" className="form-control" value={opt_d} onChange={(e) => handleChange(e)} />
          </div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-3'>Correct Answer :</div>
          <div className='col-lg-7'>
          <select className="form-control" name="correct_ans" onChange={(e) => handleChange(e)}>
             <option value="0">Select Correct Option</option>
             <option value="opt_a">Option A</option>
             <option value="opt_b">Option B</option>
             <option value="opt_c">Option C</option>
             <option value="opt_d">Option D</option>
          </select>
          </div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-12 text-center'><button className='btn btn-md btn-primary'>Add Question</button></div>
        </div>
      </form>
      </div>
      </div>
    </div>
    </>
  )
}

export default EditQuestion