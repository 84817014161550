import React,{useState, useEffect } from 'react' ;
import Header from "../common/Header" ;
import LeftNav from "../common/LeftNav" ;
import { useNavigate } from "react-router-dom";
import axios from "axios";


const SelectTopic = () => {

  let navigate=useNavigate();

  const [courseid, setCourseid] = useState([]);
  useEffect(() => {
    loadUsers();
  }, []);
  const loadUsers = async () => {
    const result = await axios.get(
      "https://exam.system.webzed.in/php-files/admin/topics/topic_id.php"
    );
    setCourseid(result.data.records);
    // console.log(result.data.records);
  };

  var q=0;
  const [cat, setCat] = useState({
    topic_id: "",
    num_ques:"",
  });

  const handleChange = (e) => {
    setCat({ ...cat, [e.target.name]: e.target.value });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    var newstr=cat.topic_id;
    var l=newstr.length;
    var ind = newstr.lastIndexOf("_");
    let num_ques= newstr.substring(++ind,l);
    let topic_id= newstr.substring(0,--ind);
    if(topic_id!=""&&num_ques!="")
    {
    navigate(`/add-question/${topic_id}/${num_ques}`)
    }
    else{
        alert("Topic id or Number of Question cannot be blank")
    }
  };
  return (
    <>
    <Header/>
    <LeftNav/>
    <div className='container col-lg-7'>
      <div className='box'>
        <div className='heading'><h2 className='text-center'>Select Topic</h2></div><hr/>
        <br/>
        <div className='form-box'>
      <form onSubmit={(e) => submitForm(e)}>
        <div className='row'>
          <div className='col-lg-3'>Topic Name :</div>
          <div className='col-lg-7'>
          <select className="form-control" name="topic_id" onChange={(e) => handleChange(e)}>
            <option value="0">Select Topic</option> {courseid.map((courseid) => (
                <>
            <option value={courseid.id + "_" + courseid.topic_name+"_"+courseid.num_ques}>{courseid.topic_name}
            </option> 
            </>
            ))}
          </select>
          </div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-3'>Question:</div>
          <div className='col-lg-7'>
        <input type="text" name="topic_id" className="form-control"/>
        </div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-12 text-center'><button type='submit' className='btn btn-md btn-primary'>Select Topic</button></div>
        </div>
      </form>
      </div>
      </div>
    </div>
    </>
  )
}

export default SelectTopic;