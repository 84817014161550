import './Responsive.css';
import { BrowserRouter as Router , Routes, Route } from 'react-router-dom';
import Home from './components/Home';
// <-------------------- Admin Pages -------------------->
import AdminLogin from './admin/AdminLogin';
import AdminHome from './admin/AdminHome';
import AddStudent from './admin/student_details/AddStudent';
import ViewStudent from './admin/student_details/ViewStudent';
import EditStudent from './admin/student_details/EditStudent';
import AddCourse from './admin/courses/AddCourse';
import ViewCourse from './admin/courses/ViewCourse';
import EditCourse from './admin/courses/EditCourse';
import AddTopics from './admin/topics/AddTopics';
import ViewTopics from './admin/topics/ViewTopics';
import EditTopics from './admin/topics/EditTopics';
import AddQuestion from './admin/questions/AddQuestion';
import ViewQuestionTopics from './admin/questions/ViewQuestionTopics';
import EditQuestion from './admin/questions/EditQuestion';
import QuestionDetails from './admin/questions/QuestionDetails';
import AllResults from './admin/result/AllResults';
// <-------------------- User Pages -------------------->
import UserLogin from './student/UserLogin';
import UserHome from './student/UserHome';
import CoursePage from './student/CoursePage';
import ExamInstr from './student/exam/ExamInstr';
import StartExam from './student/exam/StartExam';
import SelectTopic from './admin/questions/SelectTopic';
import ExamGiven from './student/exam/ExamGiven';
import Result from './student/exam/Result';
function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route element={<Home/>} path="/" />    
{/* <-------------------- Admin Routes --------------------> */}
        <Route element={<AdminLogin/>} path="/adminLogin" />
        <Route element={<AdminHome/>} path="/adminHome" />
        <Route element={<AddStudent/>} path="/add-student" />
        <Route element={<ViewStudent/>} path="/view-student" />
        <Route element={<EditStudent/>} path="/edit-student/:id" />
        <Route element={<AddCourse/>} path="/add-course" />
        <Route element={<ViewCourse/>} path="/view-course" />
        <Route element={<EditCourse/>} path="/edit-course/:id" />
        <Route element={<AddTopics/>} path="/add-topics" />
        <Route element={<ViewTopics/>} path="/view-topics" />
        <Route element={<EditTopics/>} path="/edit-topic/:id" />
        <Route element={<AddQuestion/>} path="/add-question" />
        <Route element={<ViewQuestionTopics/>} path="/view-question-topic"/>
        <Route element={<QuestionDetails/>} path="/quest-detail/:id" />
        <Route element={<EditQuestion/>} path="/edit-question/:id" />
        <Route element={<AllResults/>} path="/all-results" />
{/* <-------------------- User Routes --------------------> */}
        <Route element={<UserLogin/>} path="/userLogin" />
        <Route element={<UserHome/>} path="/UserHome/:username" />
        <Route element={<CoursePage/>} path="/CoursePage/:username" />
        <Route element={<ExamInstr/>} path="/ExamInstr/:username" />
        <Route element={<StartExam/>} path="/StartExam/:username/:topic_id" />
        <Route element={<SelectTopic/>} path="/select-topic" />
        <Route element={<ExamGiven/>} path="/exam-given/:username/:topic_id" />
        <Route element={<Result/>} path="/result/:username" />
      </Routes>
    </Router>
    </>
  );
}

export default App;
