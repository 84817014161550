import React,{useState}from 'react';
import Header from "../common/Header" ;
import LeftNav from "../common/LeftNav" ;
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AddCourse = () => {

  let history = useNavigate();
  const [cat, setCat] = useState({
    course_name: "",
    description: "",
  });
  const { course_name, description } = cat;

  const handleChange = (e) => {
    setCat({ ...cat, [e.target.name]: e.target.value });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    await axios
      .post(
        "https://exam.system.webzed.in/php-files/admin/courses/add_course.php",
        cat
      )
      .then((result) => {
        console.log(result.data);
        if (result.data.status === "valid") {
          history(`/view-course`);
        } else {
          alert("There is problem in adding,please try again");
        }
      });
  };
  return (
    <>
    <Header/>
    <LeftNav/>
    <div className='container col-lg-7'>
      <div className='box'>
        <div className='heading'><h2 className='text-center'>Add Courses</h2></div><hr/>
        <br/>
        <div className='form-box'>
      <form onSubmit={(e) => submitForm(e)}>
        <br/>
        <div className='row'>
          <div className='col-lg-3'>Course Name:</div>
          <div className='col-lg-7'><input type="text" name="course_name" className="form-control" value={course_name} onChange={(e) => handleChange(e)} class="form-control" placeholder="Add New Course" /></div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-3'>Description:</div>
          <div className='col-lg-7'><textarea name="description" value={description} onChange={(e) => handleChange(e)} class="form-control" placeholder="Course Description" /></div>
        </div>
        <br/>
        <div className='row'>
          <div className='col-lg-12 text-center'><button type='submit' className='btn btn-md btn-primary'>Add Course</button></div>
        </div>
      </form>
      </div>
      </div>
    </div>
    </>
  )
}

export default AddCourse