import React from 'react';
import Header from "../common/Header";
import LeftNav from "../common/LeftNav";

const EditStudent = () => {
  return (
    <>
    <Header/>
    <LeftNav/>
    <div className='d-flex justify-center'><h1 style={{"color":"white"}}>This Page is Coming Soon.</h1></div>
    </>
  )
}

export default EditStudent