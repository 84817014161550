import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { NavLink, Link } from 'react-router-dom';
import "./style.css"
import {GiHamburgerMenu} from "react-icons/gi";

function LeftNav() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isActive, setIsActive] = useState(false);

  const handleClick = event => {
    // toggle isActive state on click
    setIsActive(current => !current);
    console.log(isActive);
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow} className='menu-btn'>
        <span><GiHamburgerMenu/></span>Menu
      </Button>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>By Obaidullah Zeeshan</Offcanvas.Title>
        </Offcanvas.Header>
        <div className='sidebar-main'>
        <div className='sidebar-logo'>
            <Link to="/"><div className='logo-text text-center'>Quiz <br/>Competetion</div></Link>
        </div>
        <div className='sidebar'>
        <div class="dropdown sidenav">
                <button type="button" class="btn dropdown-toggle" data-toggle="dropdown">
                Student Details
                </button>
                <div class="dropdown-menu">
                <Link class="dropdown-item" to="/add-student">Add Student</Link>
                <Link class="dropdown-item" to="/view-student">View Student</Link>
                </div>
        </div>
        <div class="dropdown sidenav">
                <button type="button" class="btn dropdown-toggle" data-toggle="dropdown">
                Courses
                </button>
                <div class="dropdown-menu">
                <Link class="dropdown-item" to="/add-course">Add Course</Link>
                <Link class="dropdown-item" to="/view-course">View Course</Link>
                </div>
        </div>
        <div class="dropdown sidenav">
                <button type="button" class="btn dropdown-toggle" data-toggle="dropdown">
                Topics
                </button>
                <div class="dropdown-menu">
                <Link class="dropdown-item" to="/add-topics">Add Topic</Link>
                <Link class="dropdown-item" to="/view-topics">View Topic</Link>
                </div>
        </div>
        <div class="dropdown sidenav">
                <button type="button" class="btn dropdown-toggle" data-toggle="dropdown">
                Questions
                </button>
                <div class="dropdown-menu">
                <Link class="dropdown-item" to="/add-question">Add Questions</Link>
                <Link class="dropdown-item" to="/view-question-topic">View Questions</Link>
                </div>
          </div>
          <div class="sidenav">
                <Link to="/all-results"><button type="button" class="btn">
                Result
                </button></Link>
          </div>
        </div>
        </div>
      </Offcanvas>
    </>
  );
}

export default LeftNav;