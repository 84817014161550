import React, { useState, useEffect } from 'react' ;
import axios from "axios";
import Header from "./common/Header" ;
import LeftNav from "./common/LeftNav" ;
import { useNavigate, useParams, Link } from 'react-router-dom';

const CoursePage = () => {

  let navigate = useNavigate();

  //To check If User has Logged IN
  // const [auth, setAuth] = useState("");
  // useEffect(() => {
  //   var auth = sessionStorage.getItem("admin_email");
  //   setAuth(auth);
  // }, []);

  // if (auth === null) {
  //   navigate("/adminlogin");
  // }
  // else{
  //   navigate(`/AdminHome`);
  // }

  
  const { username } = useParams();

  const [details, setDetails] = useState([]);

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    const result = await axios.get(
      "https://exam.system.webzed.in/php-files/student/view_student.php?username=" +
      username
    );
    setDetails(result.data.records);
    // console.log(result.data)
  };
  return (
    <>
      <Header />
      <LeftNav />
      <div className='container col-lg-8'>
      <div className='box'>
        <div className='heading'><h2 className='text-center'>Course Details</h2></div><hr/>
          <div>
          <table class="table table-hover">
    <thead>
      <tr>
        <th>Sl No.</th>
        <th>Course</th>
        <th>Course Details</th>
        <th>Start Exam</th>
      </tr>
    </thead>
    <tbody>
        {details.map((details, index) =>(
        <tr>
        <td>{index + 1}</td>
        <td>{details.course_name}</td>
        <td><button className='btn btn-md btn-primary'>Details</button></td>
        <td><Link to={`/ExamInstr/${username}`}><button className='btn btn-md btn-primary'>Exam</button></Link></td>
        </tr>
        ))}
    </tbody>
  </table>
          </div>
      </div>
    </div>
    </>
  )
}

export default CoursePage