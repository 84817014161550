import React, { useState, useEffect } from 'react'
import './Login.css';
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";

const AdminLogin = () => {
    let navigate = useNavigate();

  // To check If User has Logged IN
  // const [auth, setAuth] = useState("");
  // useEffect(() => {
  //   var auth = sessionStorage.getItem("admin_email");
  //   setAuth(auth);
  // }, []);

  // if (auth === null) {
  //   navigate("/adminlogin");
  // }
  // else{
  //   navigate(`/AdminHome`);
  // }

   const [user, setUser] = useState({ email: "", password: "" });
  
    const handleChange = (e) => {
      setUser({ ...user, [e.target.name]: e.target.value });
    };
  
    const submitForm = (e) => {
      e.preventDefault();
      const sendData = {
        email: user.email,
        password: user.password,
      };
  
      // console.log(sendData);
  
      axios
        .post(
          "https://exam.system.webzed.in/php-files/admin/register-login/login.php",
          sendData
        )
        .then((result) => {
          if (result.data.Status === "200") {
            //var admin_email= result.data.email;
            //var admin_username= result.data.first_name + " " + result.data.last_name ;
            window.sessionStorage.setItem("admin_email", result.data.email);
            window.sessionStorage.setItem("admin_username", result.data.first_name + " " + result.data.last_name);
            navigate(`/adminHome`);
            //console.log(`Admin Email = ${admin_email} Admin Username = ${admin_username}`)
          } else {
            alert("Invalid User");
          }
        });
    };

  return (
    <>
    <div class="wrapper">
        <div class="logo">
            <img src="images/admin-profile.jpg" alt=""/>
        </div>
        <div class="text-center mt-4 name">
            Admin Login
        </div>
        <form class="p-3 mt-3" onSubmit={submitForm}>
            <div class="form-field d-flex align-items-center">
                <span class="far fa-user"></span>
                <input type="text" name="email" id="userName" placeholder="Email" onChange={handleChange}/>
            </div>
            <div class="form-field d-flex align-items-center">
                <span class="fas fa-key"></span>
                <input type="password" name="password" id="pwd" placeholder="Password" onChange={handleChange}/>
            </div>
            <button class="btn mt-3" type='submit'>Login</button>
        </form>
        <div className='text-center'>Go Back to <NavLink to="/" className="text-center"><span style={{fontSize: "20px"}}>Home</span></NavLink></div>
    </div>
    </>
  )
}

export default AdminLogin