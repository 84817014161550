import React, {useState, useEffect} from 'react' ;
import axios from "axios";
import Header from "../common/Header" ;
import LeftNav from "../common/LeftNav" ;
import { Link, useParams ,useNavigate} from 'react-router-dom';

const ExamInstr = () => {

  const{username} = useParams();
  const navigate=useNavigate();

  const [details, setDetails] = useState([]);
  const [data,setData]= useState([]);
  useEffect(() => {
    loadUsers();
    loadExamstatus();
  }, []);

  const loadUsers = async () => {
    const result = await axios.get(
      "https://exam.system.webzed.in/php-files/student/view_topic_by_id.php?username="+username);
    setDetails(result.data.records);
    // console.log(result.data)

  };
  const loadExamstatus = async () => {
    const resp = await axios.get(
      `https://exam.system.webzed.in/php-files/student/test/view-exam-status.php?username=${username}`);
      setData(resp.data);
    // console.log("Response Data "+resp.data.status)

  };
  function handleClick (id,topic_name,time){
    if(data.status==="Completed")
    {
      alert("You Have Given this exam");
    }
    else
    {
    const sendData ={
      topic_id:id,
      username:username,
      topic_name:topic_name,
      status:"Completed",
    };
    const updateStatus = async () => {
      await axios
        .post(
          "https://exam.system.webzed.in/php-files/student/test/exam_status.php",sendData)
        .then((resp) => {
          console.log(resp.data);
          if (resp.data.status === "valid") 
          {
            navigate(`/StartExam/${username}/${id}`,{state:{time:time}});
          } else {
            console.log("There is problem in adding,please try again");
          }
        });
    };
    updateStatus();
    }
  };
  
  return (
    <>
    <Header />
    <LeftNav />
    <div className='container col-lg-8'>
    <div className='box'>
      <div className='heading'><h2 className='text-center'>Exam Instructions</h2></div><hr/>
        <p className='text-left' style={{"fontSize": "18px","padding": "15px"}}>Veniam ea sunt ex minim irure consectetur duis adipisicing. 
        Laborum sunt ea commodo tempor consectetur pariatur veniam sunt consequat nostrud adipisicing. 
        Sit veniam mollit proident adipisicing mollit aute ullamco et deserunt esse deserunt cillum 
        cupidatat. Sint nisi nulla minim fugiat eiusmod aliquip labore ullamco sunt in commodo 
        amet aute in. Dolor irure duis minim excepteur minim id minim nostrud excepteur. 
        Officia eiusmod pariatur fugiat cillum non ipsum sit ullamco velit eiusmod id. Minim anim 
        laboris irure reprehenderit consequat occaecat.</p>
        <div>
        <table class="table table-hover">
  <thead>
    <tr>
      <td>Sl No.</td>
      <td>Your Course</td>
      <td>Topic</td>
      <td>Exam Time</td>
      <td>Exam Status</td>
      <td>Start</td>
    </tr>
  </thead>
  <tbody>
      {details.map((details, index) =>(
      <tr>
      <td>{index + 1}</td>
      <td>{details.course_name}</td>
      <td>{details.topic_name}</td>
      <td>{`${details.time}mins`}</td>
      <td><span className={`${data.status === undefined? `text-danger`:`text-success`}`}><b>{data.status === undefined? "Pending":"Completed"}</b></span></td>
      <td><button className='btn btn-md btn-primary' onClick={()=>handleClick(details.id,details.topic_name,details.time)} data-prevent-double-submit>Start Exam</button></td>
        </tr>
        ))}
  </tbody>
</table>
        </div>
    </div>
  </div>
    </>
  )
}

export default ExamInstr